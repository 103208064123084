import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","MultisessionAppSupport","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["EmailLinkErrorCode","MagicLinkErrorCode","WithClerk","WithSession","WithUser","isClerkAPIResponseError","isEmailLinkError","isKnownError","isMagicLinkError","isMetamaskError","useAuth","useClerk","useEmailLink","useMagicLink","useOrganization","useOrganizationList","useOrganizations","useSession","useSessionList","useSignIn","useSignUp","useUser","withClerk","withSession","withUser"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile"] */ "/vercel/path0/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/frontend/src/components/base/ThemeRegistry/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AlertProvider"] */ "/vercel/path0/packages/frontend/src/components/context/AlertContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoadingProvider"] */ "/vercel/path0/packages/frontend/src/components/context/LoadingContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/frontend/src/styles/globals.css");
