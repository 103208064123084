"use client";

import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import NextAppDirEmotionCacheProvider from "./EmotionCache";
import theme from "./theme";
export default function ThemeRegistry({
  children
}: {
  children: React.ReactNode;
}) {
  return <NextAppDirEmotionCacheProvider options={{
    key: "mui"
  }} data-sentry-element="NextAppDirEmotionCacheProvider" data-sentry-component="ThemeRegistry" data-sentry-source-file="index.tsx">
      <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-source-file="index.tsx">
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="index.tsx" />
        {children}
      </ThemeProvider>
    </NextAppDirEmotionCacheProvider>;
}